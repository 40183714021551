import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import {
  CountryFormConfiguration,
  DEFAULT_COUNTRY_CODES,
  DEFAULT_NAMES_PATTERN,
} from "../constants";
import {
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
  DIGITS_AND_OR_LATIN_LETTERS_REGEX,
} from "./defaults";

export const BelgiumFR: CountryFormConfiguration = {
  id: "fr-BE",
  forms: {
    personalInfo: {
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("fr-BE"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value:
              /^(?=.*[A-Za-zÁÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù])(?=.*\d)[A-Za-zÁÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù\d\s]+$/,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 4, errorMessage: "TOO_LONG" },
          minLength: { value: 4, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: { value: /^\d{4}$/, errorMessage: "POSTAL_CODE_SIZE" },
          mask: "####",
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: "BIRTHDAY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      tins: {
        name: "tins",
        label: "tins",
        type: "tins",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("fr-BE"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value:
              /^(?=.*[A-Za-zÁÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù])(?=.*\d)[A-Za-zÁÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù\d\s]+$/,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 4, errorMessage: "TOO_LONG" },
          minLength: { value: 4, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: { value: /^\d{4}$/, errorMessage: "POSTAL_CODE_SIZE" },
          mask: "####",
        },
      },
    },
  },
  addressLength: 40,
  cityLength: 30,
  addressPattern:
    /^(?=.*[A-Za-zÁÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù])(?=.*\d)[A-Za-zÁÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù\d\s]+$/,
  postalCodeLength: 4,
  postalCodeMask: "####",
  postalCodePattern: /^\d{4}$/,
  postalCodeRequired: true,
  province: false,
  adultAge: 18,
  defaultCountryCode: "+32|Belgium",
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  middleInitial: false,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDateOfBirthDisclaimer: true,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPaymentAcknowledgment: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  supportedCountryCodes: DEFAULT_COUNTRY_CODES,
  residentTins: [
    {
      key: "tinType",
      required: false,
      minLength: 10,
      maxLength: 14,
      pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
      showTinDates: false,
      documentTypes: [
        {
          key: "BEVT",
          pattern: /^BE(?!0123456789|1234567890)[0-1]\d{9}$/g,
          defaultValue: "BE",
        },
        {
          key: "BENO",
          pattern: /^[0-1](?!123456789|234567890)\d{9}$/g,
        },
      ],
    },
  ],
} as const;
