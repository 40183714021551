import { staticAssetsApi } from "../../api/staticAssets";
import { PublicAdministrationStatus } from "../../features/personal-info/publicAdministration/publicAdministrationStore";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { IdentityConfirmationState } from "../../features/personal-info/tins/tinsStore";
import { CountryFormConfiguration, DEFAULT_NAMES_PATTERN } from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
} from "./defaults";
import { VisibleOnRule } from "../model/TinRules";

const FISC_TIN_REGEX = new RegExp(
  "^[a-zA-Z]{6}\\d{2}[a-zA-Z]{1}\\d{2}[a-zA-Z]{1}\\d{1}[a-zA-Z0-9]{1}\\d{1}[a-zA-Z]{1}$"
);

const UE_COUNTRIES = [
  "AUSTRIA",
  "BELGIO",
  "BULGARIA",
  "CROAZIA",
  "CIPRO",
  "REPUBBLICA CECA",
  "DANIMARCA",
  "ESTONIA",
  "FINLANDIA",
  "FRANCIA",
  "GERMANIA",
  "GRECIA",
  "UNGHERIA",
  "IRLANDA",
  "ITALIA",
  "LETTONIA",
  "LITUANIA",
  "LUSSEMBURGO",
  "MALTA",
  "PAESI BASSI",
  "POLONIA",
  "PORTOGALLO",
  "ROMANIA",
  "SLOVACCHIA",
  "SLOVENIA",
  "SPAGNA",
  "SVEZIA",
];

const placeOfBirthCountryGetOptions = async (): Promise<string[]> =>
  await staticAssetsApi.getStaticAssets<string[]>("it-it", "countries.json");

const placeOfBirthProvinceGetOptions = async (): Promise<string[]> =>
  await staticAssetsApi.getStaticAssets<string[]>("it-it", "provinces.json");

const placeOfBirthCityGetOptions = async (
  province: string = ""
): Promise<string[]> => {
  const provinceFileName = `${province.replace(/[^a-zA-Z]/g, "").toLowerCase()}.json`;
  if (!province) {
    return [];
  }
  return await staticAssetsApi.getStaticAssets<string[]>(
    "it-it",
    provinceFileName
  );
};

export const Italy: CountryFormConfiguration = {
  id: "it-IT",
  forms: {
    personalInfo: {
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      gender: {
        name: "gender",
        label: "GENDER",
        type: "gender",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      placeOfBirth: {
        name: "placeOfBirth",
        label: "PLACE_OF_BIRTH",
        type: "object",
        validation: {
          required: { value: false },
        },
        rule: {
          isHidden: true,
        },
        fields: {
          country: {
            name: "placeOfBirthCountry",
            label: "PLACE_OF_BIRTH_COUNTRY",
            type: "asyncAutocomplete",
            defaultValue: "ITALIA",
            getOptions: placeOfBirthCountryGetOptions,
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
              maxLength: { value: 40, errorMessage: "TOO_LONG" },
            },
          },
          province: {
            name: "placeOfBirthProvince",
            label: "PLACE_OF_BIRTH_PROVINCE",
            type: "asyncAutocomplete",
            defaultValue: "",
            dependenciesNames: ["placeOfBirthCountry"],
            getOptions: placeOfBirthProvinceGetOptions,
            rule: {
              visibleOn: [
                "placeOfBirthCountry",
                (placeOfBirthCountry: string) =>
                  placeOfBirthCountry === "ITALIA",
              ],
            },
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
          },
          city: {
            name: "placeOfBirthCity",
            label: "PLACE_OF_BIRTH_CITY",
            type: "asyncAutocomplete",
            defaultValue: "",
            getOptions: placeOfBirthCityGetOptions,
            dependenciesNames: ["placeOfBirthProvince"],
            rule: {
              visibleOn: [
                "placeOfBirthProvince",
                (placeOfBirthProvince: string) => Boolean(placeOfBirthProvince),
              ],
            },
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
            },
          },
          residencePermitAttachment: {
            name: "placeOfBirth-residencePermit-attachment",
            label: "PLACE_OF_BIRTH_ATTACHMENT",
            key: "place-of-birth-attachment",
            type: "file",
            index: 0,
            attachmentType: AttachmentType.RESIDENCE_PERMIT,
            middleDescription: "PLACE_OF_BIRTH_ATTACHMENT_DESCRIPTION",
            validation: {
              acceptedExtensions: {
                value: [".pdf", ".bmp", ".jpg", ".jpeg"],
                errorMessage: "UPLOAD_FILE_INVALID_EXTENSION",
              },
              required: { value: false, errorMessage: "REQUIRED" },
            },
            rule: {
              visibleOn: [
                "placeOfBirthCountry",
                (placeOfBirthCountry: string) =>
                  !UE_COUNTRIES.includes(placeOfBirthCountry),
              ],
            },
          },
        },
      },
      profilePhotoAttachment: {
        name: "profile-photo-attachment",
        label: "PROFILE_PHOTO_UPLOAD_FILE_INFO",
        key: "profile-photo-attachment",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.FACE_PHOTO,
        topDescription: "PROFILE_PHOTO_UPLOAD_FILE_LABEL",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: [".bmp", ".jpg", ".jpeg"],
            errorMessage: "PROFILE_PHOTO_INVALID_EXTENSION",
          },
        },
      },
      isPublicAdministrationEmployee: {
        name: "isPublicAdministrationEmployee",
        label: "IS_PUBLIC_ADMINISTRATION",
        type: "radio",
        defaultValue: PublicAdministrationStatus.NOT_EMPLOYED,
        options: [
          PublicAdministrationStatus.EMPLOYED,
          PublicAdministrationStatus.NOT_EMPLOYED,
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
      },
      publicAdministrationAttachment: {
        name: "publicAdministration-attachment",
        label: "PUBLIC_ADMINISTRATION_ATTACHMENT",
        key: "publicAdministration-attachment",
        middleDescription: "PUBLIC_ADMINISTRATION_ATTACHMENT_DISCLAIMER",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.AUTHORIZATION_DOCUMENT,
        validation: {
          required: { value: false, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: [".pdf", ".bmp", ".jpg", ".jpeg"],
            errorMessage: "UPLOAD_FILE_INVALID_EXTENSION",
          },
        },
        rule: {
          visibleOn: [
            "isPublicAdministrationEmployee",
            (isPublicAdministrationEmployee: string) =>
              isPublicAdministrationEmployee ===
              PublicAdministrationStatus.EMPLOYED.toString(),
          ],
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "experian",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("it-IT"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "#####",
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "asyncAutocomplete",
        defaultValue: "",
        getOptions: placeOfBirthProvinceGetOptions,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      country: {
        name: "country",
        label: "COUNTRY",
        type: "text",
        defaultValue: "Italy",
        validation: {
          required: { value: false },
        },
        rule: {
          isDisabled: true,
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: "BIRTHDAY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      tins: {
        name: "tins",
        label: "tins",
        type: "tins",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "experian",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("it-IT"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "asyncAutocomplete",
        defaultValue: "",
        getOptions: placeOfBirthProvinceGetOptions,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "#####",
        },
      },
    },
  },
  addressLength: 50,
  addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
  cityLength: 40,
  postalCodeLength: 5,
  postalCodeMask: "#####",
  postalCodePattern: /^[0-9]{5}$/,
  postalCodeRequired: true,
  province: true,
  provinceLength: 40,
  provinceRequired: true,
  showDeliveryDisclaimer: false,
  defaultCountryValue: "Italia",
  adultAge: 18,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLogos: [
    {
      src: "logos/dsa.png",
      alt: "dsa logo",
    },
  ],
  genderFieldRequired: true,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDataPrivacyAcknowledgment: true,
  showDateOfBirthDisclaimer: true,
  showDocumentationBox: false,
  showFirstNameDisclaimer: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showIdentificationCardLink: true,
  showLastNameDisclaimer: false,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  hideFooterTerms: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: false,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["it"],
  defaultCountryCode: "+39|Italy",
  isDataPrivacyAcknowledgement: true,
  residentTins: [
    {
      key: "ssn",
      required: true,
      visibleOn: [
        "$identityConfirmationState",
        (identityConfirmationState: IdentityConfirmationState) =>
          identityConfirmationState !== IdentityConfirmationState.FAILED,
      ] as VisibleOnRule<IdentityConfirmationState>,
      minLength: 16,
      maxLength: 16,
      pattern: FISC_TIN_REGEX,
      showTinDates: false,
      attachments: [
        {
          visibleOn: [
            "$identityConfirmationState",
            (identityConfirmationState: IdentityConfirmationState) =>
              identityConfirmationState === IdentityConfirmationState.FAILED,
          ] as VisibleOnRule<IdentityConfirmationState>,
          required: true,
          key: "resident-attachment-0",
          index: 0,
          type: AttachmentType.TAX_NUMBER,
          validation: {
            acceptedExtensions: {
              value: [".pdf", ".bmp", ".jpg", ".jpeg"],
              errorMessage: "UPLOAD_FILE_INVALID_EXTENSION",
            },
          },
        },
      ],
    },
  ],
  attachments: [
    {
      required: true,
      key: "profile-photo-attachment",
      type: AttachmentType.FACE_PHOTO,
      validation: {
        acceptedExtensions: {
          value: [".bmp", ".jpg", ".jpeg"],
          errorMessage: "PROFILE_PHOTO_INVALID_EXTENSION",
        },
      },
      uploadFileLabelKey: "PROFILE_PHOTO_UPLOAD_FILE_LABEL",
    },
  ],
} as const;
