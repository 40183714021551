import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { CountryFormConfiguration, DEFAULT_NAMES_PATTERN } from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
} from "./defaults";

export const SwitzerlandCH: CountryFormConfiguration = {
  id: "de-CH",
  forms: {
    personalInfo: {
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("de-CH"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 4, errorMessage: "TOO_LONG" },
          minLength: { value: 4, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{4}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "####",
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: "BIRTHDAY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      tins: {
        name: "tins",
        label: "tins",
        type: "tins",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("de-CH"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 4, errorMessage: "TOO_LONG" },
          minLength: { value: 4, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{4}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "####",
        },
      },
    },
  },
  addressLength: 40,
  addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
  cityLength: 30,
  postalCodeLength: 4,
  postalCodeRequired: true,
  postalCodeMask: "####",
  postalCodePattern: /^[0-9]{4}$/,
  province: false,
  provinceLength: defaultCountryConfigFieldLengths.province,
  adultAge: 18,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLogos: [
    {
      src: "logos/Visa.png",
      alt: "Visa logo",
    },
    {
      src: "logos/Mastercard.png",
      alt: "Mastercard logo",
    },
  ],
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDateOfBirthDisclaimer: true,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["ch"],
  defaultCountryCode: "+41|Switzerland",
} as const;
