import axios from "axios";

const getStaticAssets = async <Data>(
  locale: string,
  name: string
): Promise<Data> => {
  const response = await axios.get<Data>(`/static/${locale}/${name}`, {
    baseURL: import.meta.env.VITE_CLIENT_URL,
    timeout: 6000,
  });

  return response.data;
};

const getStaticBlobAssets = async (
  locale: string,
  name: string
): Promise<Blob> => {
  const response = await axios.get<Blob>(`/static/${locale}/${name}`, {
    baseURL: import.meta.env.VITE_CLIENT_URL,
    responseType: "blob",
    timeout: 6000,
  });
  return response.data;
};

export const staticAssetsApi = {
  getStaticAssets,
  getStaticBlobAssets,
};
